.tableRow:nth-child(odd) {
  background-color: #fff;
}

.tableRow:nth-child(even) {
  background-color: #f9f9f9;
}

.td {
  padding: 10px;
  border-bottom: 1px solid #eee;
  color: #373A3C;
  cursor: pointer;
}
.td:first-child {
  width: 50px;
}

.tableRow.selectRow {
  background-color: #bbb;
}