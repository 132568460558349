.HomePage {
  .restaurantsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 64px;
    padding: 0 20px;

    .noRestaurants {
      color: #373A3C;
      font-size: 20px;
      margin-top: 150px; 
    }
  }
}