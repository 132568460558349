.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #373A3C;
  position: relative;
  margin-top: 20px;

  .title {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
  }

  .timer {
    font-size: 25px;
    position: absolute;
    right: 50px;
  }
}

@media only screen and (max-width: 700px) {
  .titleContainer {
    flex-direction: column-reverse;
    .timer {
      position: static;
      margin-bottom: 30px;
    }
  }
}