.PlaceDetailPage {
  .placeDetail {
    max-width: 700px;
    margin: 50px auto 0;
    color: #373A3C;
    font-size: 20px;
  }

  .subDetail {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .detail {
    margin-top: 50px;
  }
  .message {
    margin-top: 50px;
    text-align: center;
    font-size: 20px;
  }

  .btnContainer {
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .btn {
    margin-top: 140px;
    background-color: #DC5555;
    color: #fff;
    outline: none;
    border: none;
    max-width: 300px;
    width: 100%;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;

    &.btnCancel {
      background-color: #a9a9a9;
    }
  }
}