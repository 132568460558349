.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  .logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #373A3C;
    cursor: pointer;
  }

  .logoText {
    font-weight: bold;
    font-size: 35px;
    padding: 5px 10px;
    text-transform: uppercase;
  }
  
  .dropbtn {
    padding: 16px;
    font-size: 25px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    max-width: 160px;
  }

  .dropdownContent {
    max-width: 160px;
    padding: 0 16px;
    display: none;
    position: absolute;
    color: #f4f4f4;
    z-index: 1;
  }

  .dropdownContent div {
    width: 96px;
    padding: 10px 16px;
    background-color: #373A3C;
    color: #f4f4f4;
    border-radius: 2px;
    text-decoration: none;
    display: block;
    border: 1px solid transparent;
    cursor: pointer;
  }
  .dropdownContent div:hover {
    color: #373737;
    background-color: #f4f4f4;
    border: 1px solid #373A3C;
    text-decoration: none;
    display: block;
  }

  .isShow.dropdownContent {display: block;}
}

@media only screen and (max-width: 700px) {
  .Header {
    .logoText {
      font-weight: bold;
      font-size: 25px;
      padding: 5px 10px;
      text-transform: uppercase;
    }
    .account {
      font-size: 20px;
    }
  }
}