.PlacesList {
  max-width: 980px;
  width: 100%;
  .table {
    border-spacing: 0;
    width: 100%;
  }
  .tableHeader {
    color: #f4f4f4;
    background-color: #373A3C;
    .th {
      font-weight: 300;
      padding: 10px;
      text-align: left;
    }
  }
  .btnContainer {
    margin: 20px auto;
    text-align: center;
  }
  .pagination {
    margin: 30px 0;
    .paginationBttns {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 330px;
      width: 100%;
      margin: 0 auto;
      li {
        list-style-type: none;
        border: 1px solid #373A3C;
        box-sizing: border-box;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      a {
        padding: 10px;
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        text-align: right;
        color: #373A3C;
      }
      .previous,
      .next {
        border: none;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .paginationActive {
        background-color: #373A3C;
        a {
          color: #f4f4f4;
        }
      }
    }
  }
} 