.CheckBox {
  position: relative;
  bottom: 5px;
  padding-left: 21px;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #373A3C;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.CheckBox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #666666;
  box-sizing: border-box;
  border-radius: 3px;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.CheckBox input:checked ~ .checkmark:after {
  display: block;
}

.CheckBox .checkmark:after {
  left: 3.5px;
  top: -1px;
  width: 6px;
  height: 10px;
  border: solid #666666;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
