.AvailablePlacesPage {
  .titleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #373A3C;
    position: relative;
    margin-top: 20px;
  
    .title {
      text-align: center;
      font-size: 30px;
      font-weight: bold;
    }
  
    .timer {
      font-size: 25px;
      position: absolute;
      right: 50px;
    }
  }
  
  @media only screen and (max-width: 700px) {
    .titleContainer {
      flex-direction: column-reverse;
      .timer {
        position: static;
        margin-bottom: 30px;
      }
    }
  }
  .placesContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 64px 0;
    padding: 0 20px;

    .noSlots {
      color: #373A3C;
      font-size: 20px;
      margin-top: 150px; 
    }
  }
}