.PlacesList {
  max-width: 980px;
  width: 100%;
  .table {
    border-spacing: 0;
    width: 100%;
  }
  .tableHeader {
    color: #f4f4f4;
    background-color: #373A3C;
    .th {
      font-weight: 300;
      padding: 10px;
      text-align: left;
    }
  }
  .btnContainer {
    margin: 20px auto;
    text-align: center;
  }
} 