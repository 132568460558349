.FormSlot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #373A3C;

  .title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .selectItem {
    width: 100%;
    .titleMeal {
      text-align: left;
      padding: 8px 15px;
      border-radius: 3px;
      background: #f6f6f6;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 15px;
    }
  }

  .listContainer {
    list-style-type: none;

    .label {
      padding-left: 10px;
    }
    .container {
      display: block;
      position: relative;
      display: flex;
      align-items: center;
      font-size: 18px;
      padding-left: 15px;
      margin-bottom: 12px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    
    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 12px;
      width: 12px;
      background-color: #fff;
      border: 1px solid #373A3C;
      border-radius: 50%;
      margin-top: 4px;
      margin-right: 10px;
    }
    
    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: white;
    }
    
    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    
    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }
    
    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      top: 3px;
      left: 3px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #373A3C;
    }
  }

  .numberGuest {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    .numberGuestTitle {
      padding: 8px 15px;
      font-weight: 600;
      font-size: 18px;
    }
    .numberSelect {
      outline: none;
      width: 50px;
    }
  }
  .labelCourse {
    padding-left: 10px;
  } 
  .btnContainer {
    margin: 0 auto;
    text-align: center;
    button {
      padding: 10px;
      outline: none;
      background-color: #373A3C;
      color: #f4f4f4;
      border: 1px solid transparent;
      border-radius: 5px;
      font-size: 20px;
      box-sizing: border-box;
      cursor: pointer;
      transition: all .2s;
    }
    button:hover {
      background-color: #f4f4f4;
      color: #373A3C;
      border: 1px solid #373A3C;
    }
    button:disabled {
      background-color: silver;
      color: #373A3C;
      border: 1px solid transparent;
      cursor: default;
    }
  }
}