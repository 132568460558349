.LoginPage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  .wrapp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .form {
    width: 300px;
  }
  .linkContainer {
    margin-top: 100px;
    text-align: center;
    .link {
      color: #373A3C;
      font-size: 18px;
      font-weight: 500;
    }
  }
  .inputsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
  }
  .checkBoxContainer {
    margin-top: 16px;
    display: flex;
    justify-content: center;
  }
  .btnContainer {
    margin-top: 27px;
    width: 100%;
  }
}