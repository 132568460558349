.inputField {
  padding: 10px;
  outline: none;
  border: 1px solid #CCCCCC;
  color: #373A3C;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  &.topField {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &.bottomField {
    border-top: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}