.SearchRestaurants {
  padding: 0 20px;

  .searchContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 980px;
    width: 100%;
    margin: 0 auto;
    background-color: #373A3C;
    color: #f4f4f4;
    padding: 13px;
    border-radius: 8px;
    box-sizing: border-box;
  }

  .cancel {
    width: 30px;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* padding-right: 10px;
    padding-top: 8px; */
    font-size: 35px;
    span {
      display: none;
      &.show {
        /* padding-top: 8px; */
        width: 50px;
        transform: rotate(45deg);
        cursor: pointer;
        display: block;
      }
    }
  }
  .region {
    margin-right: 10px;
  }

  .genre {
    margin-right: 10px;
  }
  .date {
    font-family: Roboto, sans-serif;
    padding: 10px;
    max-width: 136px;
    width: 100%;
    outline: none;
    border-radius: 4px;
    border: none;
    font-size: 15px;
    color: #373A3C;
    cursor: pointer;
    background-color: #f4f4f4;
  }
  .timeContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;

    .delimiter {
      padding: 0 10px;
      font-size: 30px;
      color: #f4f4f4;
    }
  }
  .countGuest {
    margin-right: 20px;
  }

  .btnSearch {
    outline: none;
    background-color: transparent;
    color: #f4f4f4;
    border: 1px solid #f4f4f4;
    padding: 10px 25px;
    border-radius: 4px;
    cursor: pointer;
    transition: all .2s;

    &:hover {
      background-color: #f4f4f4;
      color: #373A3C;
    }
  }

}

@media only screen and (max-width: 998px) {
  .SearchRestaurants {
    .searchContainer {
      flex-wrap: wrap;
      justify-content: center;
      align-content: space-between;
      gap: 1rem;
    }
  }
}
