.FormConfirmPassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #373A3C;

  .title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .passwordField {
    margin: 20px auto 40px;
    padding: 20px;
    font-size: 15px;
    outline: none;
    color: #373A3C;
    border: 1px solid silver;
    transition: all .2s;
    border-radius: 5px;
  }
  .passwordField:focus {
    border: 1px solid #373A3C;
  }
}