.Button {
  padding: 10px;
  outline: none;
  background-color: #373A3C;
  color: #f4f4f4;
  border: 1px solid transparent;
  border-radius: 5px;
  font-size: 20px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all .2s;

  &:hover {
    background-color: #f4f4f4;
    color: #373A3C;
    border: 1px solid #373A3C;
  }
  &:disabled {
    background-color: silver;
    color: #373A3C;
    border: 1px solid transparent;
    cursor: default;
  }
  &.signIn {
    width: 100%;
  }
  &.isContinue {
    width: 192px;
  }
  &.buttonInForm {
    width: 100px;
    font-size: 16px;
    padding: 5px;
    height: 37px;
  }
}
