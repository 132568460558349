.logo {
  font-size: 50px;
  padding: 10px 15px;
  border-radius: 15px;
  color: #fff;
  background-color: #373A3C;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.miniLogo {
  font-size: 35px;
  padding: 5px 10px;
  border-radius: 10px;
}